<template>
  <div class="VillaComponent row">
    <component
      :is="isAdvice ? 'div' : 'router-link'"
      :to="isAdvice ? null : `/accommodaties/${villa.slug}`"
      @click="openDialog"
      class="col-xs-12 col-sm-4 pointer"
    >
      <div class="labelContainer">
        <AccommodatieGroupedLabel :labels="labels" />
      </div>

      <Image
        class="imageContainer"
        :src="villa.thumbnailImageUrl"
      />
    </component>

    <div class="col-xs-12 col-sm-8 infoContainer">
      <div>
        <component
          :is="isAdvice ? 'div' : 'router-link'"
          :to="isAdvice ? null : `/accommodaties/${villa.slug}`"
          class="col-xs-12 col-sm-4 pointer"
          @click="openDialog"
        >
          <div
            class="heading3 title"
            v-text="villa.name"
          />
        </component>

        <div
          class="place q-mt-sm"
          v-text="address"
        />
        <div
          class="description q-mt-sm raw"
          v-html="villa.shortDescription"
        />

        <div class="q-mt-sm column q-gutter-sm">
          <div
            class="row items-center"
            v-if="villa.unitCountText"
          >
            <q-icon
              name="icon-room"
              class="q-mr-xs"
            />
            <div v-text="villa.unitCountText" />
          </div>
          <div class="row textMedium">
            <div
              v-for="(facil, idx) in kenmerken"
              :key="idx"
              class="col-xs-12 col-sm-6"
            >
              <q-icon name="icon-star-full" />
              <span
                v-text="facil.name"
                class="q-ml-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="buttons"
        v-if="!isAdvice"
      >
        <q-btn
          v-if="!isSaved"
          flat
          icon="icon-bookmark-add"
          @click="emit('save-clicked', villa)"
        >
          <span
            v-t="'general.save'"
            class="q-ml-xs"
          />
        </q-btn>

        <q-btn
          v-else
          flat
          icon="icon-bookmark-added"
          @click="emit('save-clicked', villa)"
        >
          <span
            v-t="'general.saved'"
            class="q-ml-xs"
          />
        </q-btn>

        <router-link :to="`/accommodaties/${villa.slug}`">
          <q-btn
            color="primary"
            unelevated
            v-t="'landing.view'"
          />
        </router-link>
      </div>

      <div
        class="buttons"
        v-else
      >
        <q-btn @click="openDialog"> Bekijk units</q-btn>

        <q-btn
          color="primary"
          unelevated
          @click="emit('add-clicked')"
        >
          Toevoegen aan advies
        </q-btn>
      </div>
    </div>

    <!-- Prop hell want er worden een paar heftige component gebruikt voor advies -->
    <slot name="extra" />

    <!-- <DialogComponent
      v-if="showUnitsDialog"
      title="Beschikbaarheid"
      v-model="showUnitsDialog"
    >
      <q-btn
        @click="showAvailbility = !showAvailbility"
        color="primary-dark"
        outline
      >
        Laat beschikbaarheid zien</q-btn
      >
      <OwnersAccommodationDetail
        class="q-mt-md"
        v-if="showAvailbility"
        :can-edit="false"
        is-admin
        :acco-id="currentAccoId"
      />
      <AccommodatieBeschikbareUnits
        :compact="false"
        use-sub-filters
      />
    </DialogComponent> -->
  </div>
</template>

<script setup lang="ts">
import { Screen } from 'quasar';

import useCurrentAccoStore from '~/store/currentAccommodation';

const props = defineProps({
  villa: {
    type: Object as PropType<AccommodationDetail>,
    required: true,
  },
  isAdvice: {
    type: Boolean,
    default: false,
  },
  isSaved: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['save-clicked', 'add-clicked']);
const currentAccoStore = useCurrentAccoStore();

const maxKenmerken = computed(() => (Screen.width > 1550 || Screen.width < 500 ? 6 : 4));
// TOOD: Fix when BE is fixed, holidayTypes is always given
const kenmerken = computed(() =>
  [...props.villa.accommodationTypes, ...(props.villa.holidayTypes || []), ...(props.villa.qualityLabels || [])].slice(
    0,
    maxKenmerken.value
  )
);
const address = computed(() => {
  return [props.villa.region, props.villa.province].filter((item) => !!item).join(', ');
});
const labels = computed(() => {
  const list = [];
  if (props.villa?.labels) list.push(...props.villa.labels);
  if (props.villa?.discounts) list.push(...props.villa.discounts);
  return list;
});

async function openDialog() {
  if (!props.isAdvice) return;
  await currentAccoStore.getSummary(props.villa.slug, [{ imageUrl: props.villa.thumbnailImageUrl }]);
  currentAccoStore.setUnitsDialog(true);
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.sass';
.VillaComponent {
  margin: 15px 0px;
  position: relative;
  width: 100%;
}

.labelContainer {
  z-index: 2;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.imageContainer {
  height: 290px;
  border-radius: 16px;
}

.infoContainer {
  padding-left: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    color: $calm-blue-400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .place {
    color: $calm-blue-300;
  }

  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    /* Soms komen er p tags binnen met het CMS */
    :deep(p) {
      margin: 0;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}

@media (max-width: $screen-xs) {
  .VillaComponent {
    display: flex;
    flex-direction: column;
  }

  .imageContainer {
    height: 225px;
  }
  .infoContainer {
    padding-left: 0;
    margin-top: 14px;

    .buttons {
      display: none;
    }
  }

  .description {
    display: none;
  }
}
</style>

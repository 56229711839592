import { default as _91level3_93MXuZtIQ1r3Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue?macro=true";
import { default as indexOPczg5cv0HMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue?macro=true";
import { default as indexB7NMZCA97xMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/index.vue?macro=true";
import { default as _91id_93Kn5buHEgCJMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue?macro=true";
import { default as indexa8rsNnk03sMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue?macro=true";
import { default as token_45verlopense2Ma5dqjHMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue?macro=true";
import { default as _91id_93RDFfjmOXZaMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/accommodaties/[id].vue?macro=true";
import { default as indexGN6ScI1ke5Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/accommodaties/index.vue?macro=true";
import { default as account_45bewerkenZWru2wxDFBMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/account-bewerken.vue?macro=true";
import { default as accountFpChjLo8NLMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/account.vue?macro=true";
import { default as gedaan6uKaIQrK5KMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boeken/gedaan.vue?macro=true";
import { default as index67EODtmZ92Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boeken/index.vue?macro=true";
import { default as boeking_45aanmaken_45klantjxciMrAlOAMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue?macro=true";
import { default as _91bookingId_93sxdUJ8TRHDMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue?macro=true";
import { default as _91userId_93G2JAzLw26iMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue?macro=true";
import { default as index61m1GvpnEWMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/admin/index.vue?macro=true";
import { default as indexk02pdtL5G8Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/index.vue?macro=true";
import { default as contactMKMD2tWxhAMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/contact.vue?macro=true";
import { default as _91id_93uyC8GYj6KKMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/[id].vue?macro=true";
import { default as _91id_93EYlZfE5qo8Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue?macro=true";
import { default as indexvdHoi3lm9tMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue?macro=true";
import { default as indexJGxVJhutRYMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/index.vue?macro=true";
import { default as error5xCtEUyO4eMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/error.vue?macro=true";
import { default as favorietenxORFjecXYBMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/favorieten.vue?macro=true";
import { default as indexXdWx8YCq1MMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/index.vue?macro=true";
import { default as _91inquiryId_9327c4vulYRpMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue?macro=true";
import { default as indexP9FnY4hzfGMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue?macro=true";
import { default as nieuwsbriefQwoyRpCPhkMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/nieuwsbrief.vue?macro=true";
import { default as answer_45availabilityfRfXxXguqtMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/owner/answer-availability.vue?macro=true";
import { default as reis_45aanvragen_45klantRzmwaBLHIfMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue?macro=true";
import { default as reis_45aanvragen8d4c5nANneMeta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/reis-aanvragen.vue?macro=true";
import { default as team_45beheren52VF2fEnw3Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/team-beheren.vue?macro=true";
import { default as winkelwagenbFY6Wwhgw8Meta } from "C:/Werk/Repos/smookitalia-nuxt-app/pages/winkelwagen.vue?macro=true";
export default [
  {
    name: _91level3_93MXuZtIQ1r3Meta?.name ?? "slug-level2-level3",
    path: _91level3_93MXuZtIQ1r3Meta?.path ?? "/:slug()/:level2()/:level3()",
    meta: _91level3_93MXuZtIQ1r3Meta || {},
    alias: _91level3_93MXuZtIQ1r3Meta?.alias || [],
    redirect: _91level3_93MXuZtIQ1r3Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue").then(m => m.default || m)
  },
  {
    name: indexOPczg5cv0HMeta?.name ?? "slug-level2",
    path: indexOPczg5cv0HMeta?.path ?? "/:slug()/:level2()",
    meta: indexOPczg5cv0HMeta || {},
    alias: indexOPczg5cv0HMeta?.alias || [],
    redirect: indexOPczg5cv0HMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7NMZCA97xMeta?.name ?? "slug",
    path: indexB7NMZCA97xMeta?.path ?? "/:slug()",
    meta: indexB7NMZCA97xMeta || {},
    alias: indexB7NMZCA97xMeta?.alias || [],
    redirect: indexB7NMZCA97xMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Kn5buHEgCJMeta?.name ?? "aankomende-reizen-id",
    path: _91id_93Kn5buHEgCJMeta?.path ?? "/aankomende-reizen/:id()",
    meta: _91id_93Kn5buHEgCJMeta || {},
    alias: _91id_93Kn5buHEgCJMeta?.alias || [],
    redirect: _91id_93Kn5buHEgCJMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexa8rsNnk03sMeta?.name ?? "aankomende-reizen",
    path: indexa8rsNnk03sMeta?.path ?? "/aankomende-reizen",
    meta: indexa8rsNnk03sMeta || {},
    alias: indexa8rsNnk03sMeta?.alias || [],
    redirect: indexa8rsNnk03sMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue").then(m => m.default || m)
  },
  {
    name: token_45verlopense2Ma5dqjHMeta?.name ?? "aankomende-reizen-token-verlopen",
    path: token_45verlopense2Ma5dqjHMeta?.path ?? "/aankomende-reizen/token-verlopen",
    meta: token_45verlopense2Ma5dqjHMeta || {},
    alias: token_45verlopense2Ma5dqjHMeta?.alias || [],
    redirect: token_45verlopense2Ma5dqjHMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RDFfjmOXZaMeta?.name ?? "accommodaties-id",
    path: _91id_93RDFfjmOXZaMeta?.path ?? "/accommodaties/:id()",
    meta: _91id_93RDFfjmOXZaMeta || {},
    alias: _91id_93RDFfjmOXZaMeta?.alias || [],
    redirect: _91id_93RDFfjmOXZaMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/accommodaties/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGN6ScI1ke5Meta?.name ?? "accommodaties",
    path: indexGN6ScI1ke5Meta?.path ?? "/accommodaties",
    meta: indexGN6ScI1ke5Meta || {},
    alias: indexGN6ScI1ke5Meta?.alias || [],
    redirect: indexGN6ScI1ke5Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/accommodaties/index.vue").then(m => m.default || m)
  },
  {
    name: account_45bewerkenZWru2wxDFBMeta?.name ?? "account-bewerken",
    path: account_45bewerkenZWru2wxDFBMeta?.path ?? "/account-bewerken",
    meta: account_45bewerkenZWru2wxDFBMeta || {},
    alias: account_45bewerkenZWru2wxDFBMeta?.alias || [],
    redirect: account_45bewerkenZWru2wxDFBMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/account-bewerken.vue").then(m => m.default || m)
  },
  {
    name: accountFpChjLo8NLMeta?.name ?? "account",
    path: accountFpChjLo8NLMeta?.path ?? "/account",
    meta: accountFpChjLo8NLMeta || {},
    alias: accountFpChjLo8NLMeta?.alias || [],
    redirect: accountFpChjLo8NLMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: gedaan6uKaIQrK5KMeta?.name ?? "boeken-gedaan",
    path: gedaan6uKaIQrK5KMeta?.path ?? "/boeken/gedaan",
    meta: gedaan6uKaIQrK5KMeta || {},
    alias: gedaan6uKaIQrK5KMeta?.alias || [],
    redirect: gedaan6uKaIQrK5KMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boeken/gedaan.vue").then(m => m.default || m)
  },
  {
    name: index67EODtmZ92Meta?.name ?? "boeken",
    path: index67EODtmZ92Meta?.path ?? "/boeken",
    meta: index67EODtmZ92Meta || {},
    alias: index67EODtmZ92Meta?.alias || [],
    redirect: index67EODtmZ92Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boeken/index.vue").then(m => m.default || m)
  },
  {
    name: boeking_45aanmaken_45klantjxciMrAlOAMeta?.name ?? "boeking-aanmaken-klant",
    path: boeking_45aanmaken_45klantjxciMrAlOAMeta?.path ?? "/boeking-aanmaken-klant",
    meta: boeking_45aanmaken_45klantjxciMrAlOAMeta || {},
    alias: boeking_45aanmaken_45klantjxciMrAlOAMeta?.alias || [],
    redirect: boeking_45aanmaken_45klantjxciMrAlOAMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93sxdUJ8TRHDMeta?.name ?? "boekingen-bookingId",
    path: _91bookingId_93sxdUJ8TRHDMeta?.path ?? "/boekingen/:bookingId()",
    meta: _91bookingId_93sxdUJ8TRHDMeta || {},
    alias: _91bookingId_93sxdUJ8TRHDMeta?.alias || [],
    redirect: _91bookingId_93sxdUJ8TRHDMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93G2JAzLw26iMeta?.name ?? "boekingen-admin-bookingId-userId",
    path: _91userId_93G2JAzLw26iMeta?.path ?? "/boekingen/admin/:bookingId()/:userId()",
    meta: _91userId_93G2JAzLw26iMeta || {},
    alias: _91userId_93G2JAzLw26iMeta?.alias || [],
    redirect: _91userId_93G2JAzLw26iMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue").then(m => m.default || m)
  },
  {
    name: index61m1GvpnEWMeta?.name ?? "boekingen-admin",
    path: index61m1GvpnEWMeta?.path ?? "/boekingen/admin",
    meta: index61m1GvpnEWMeta || {},
    alias: index61m1GvpnEWMeta?.alias || [],
    redirect: index61m1GvpnEWMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexk02pdtL5G8Meta?.name ?? "boekingen",
    path: indexk02pdtL5G8Meta?.path ?? "/boekingen",
    meta: indexk02pdtL5G8Meta || {},
    alias: indexk02pdtL5G8Meta?.alias || [],
    redirect: indexk02pdtL5G8Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/boekingen/index.vue").then(m => m.default || m)
  },
  {
    name: contactMKMD2tWxhAMeta?.name ?? "contact",
    path: contactMKMD2tWxhAMeta?.path ?? "/contact",
    meta: contactMKMD2tWxhAMeta || {},
    alias: contactMKMD2tWxhAMeta?.alias || [],
    redirect: contactMKMD2tWxhAMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uyC8GYj6KKMeta?.name ?? "eigenaren-id",
    path: _91id_93uyC8GYj6KKMeta?.path ?? "/eigenaren/:id()",
    meta: _91id_93uyC8GYj6KKMeta || {},
    alias: _91id_93uyC8GYj6KKMeta?.alias || [],
    redirect: _91id_93uyC8GYj6KKMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EYlZfE5qo8Meta?.name ?? "eigenaren-admin-id",
    path: _91id_93EYlZfE5qo8Meta?.path ?? "/eigenaren/admin/:id()",
    meta: _91id_93EYlZfE5qo8Meta || {},
    alias: _91id_93EYlZfE5qo8Meta?.alias || [],
    redirect: _91id_93EYlZfE5qo8Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvdHoi3lm9tMeta?.name ?? "eigenaren-admin",
    path: indexvdHoi3lm9tMeta?.path ?? "/eigenaren/admin",
    meta: indexvdHoi3lm9tMeta || {},
    alias: indexvdHoi3lm9tMeta?.alias || [],
    redirect: indexvdHoi3lm9tMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexJGxVJhutRYMeta?.name ?? "eigenaren",
    path: indexJGxVJhutRYMeta?.path ?? "/eigenaren",
    meta: indexJGxVJhutRYMeta || {},
    alias: indexJGxVJhutRYMeta?.alias || [],
    redirect: indexJGxVJhutRYMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/eigenaren/index.vue").then(m => m.default || m)
  },
  {
    name: error5xCtEUyO4eMeta?.name ?? "error",
    path: error5xCtEUyO4eMeta?.path ?? "/error",
    meta: error5xCtEUyO4eMeta || {},
    alias: error5xCtEUyO4eMeta?.alias || [],
    redirect: error5xCtEUyO4eMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: favorietenxORFjecXYBMeta?.name ?? "favorieten",
    path: favorietenxORFjecXYBMeta?.path ?? "/favorieten",
    meta: favorietenxORFjecXYBMeta || {},
    alias: favorietenxORFjecXYBMeta?.alias || [],
    redirect: favorietenxORFjecXYBMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: indexXdWx8YCq1MMeta?.name ?? "index",
    path: indexXdWx8YCq1MMeta?.path ?? "/",
    meta: indexXdWx8YCq1MMeta || {},
    alias: indexXdWx8YCq1MMeta?.alias || [],
    redirect: indexXdWx8YCq1MMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91inquiryId_9327c4vulYRpMeta?.name ?? "inkomende-reisaanvragen-inquiryId",
    path: _91inquiryId_9327c4vulYRpMeta?.path ?? "/inkomende-reisaanvragen/:inquiryId()",
    meta: _91inquiryId_9327c4vulYRpMeta || {},
    alias: _91inquiryId_9327c4vulYRpMeta?.alias || [],
    redirect: _91inquiryId_9327c4vulYRpMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue").then(m => m.default || m)
  },
  {
    name: indexP9FnY4hzfGMeta?.name ?? "inkomende-reisaanvragen",
    path: indexP9FnY4hzfGMeta?.path ?? "/inkomende-reisaanvragen",
    meta: indexP9FnY4hzfGMeta || {},
    alias: indexP9FnY4hzfGMeta?.alias || [],
    redirect: indexP9FnY4hzfGMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbriefQwoyRpCPhkMeta?.name ?? "nieuwsbrief",
    path: nieuwsbriefQwoyRpCPhkMeta?.path ?? "/nieuwsbrief",
    meta: nieuwsbriefQwoyRpCPhkMeta || {},
    alias: nieuwsbriefQwoyRpCPhkMeta?.alias || [],
    redirect: nieuwsbriefQwoyRpCPhkMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/nieuwsbrief.vue").then(m => m.default || m)
  },
  {
    name: answer_45availabilityfRfXxXguqtMeta?.name ?? "owner-answer-availability",
    path: answer_45availabilityfRfXxXguqtMeta?.path ?? "/owner/answer-availability",
    meta: answer_45availabilityfRfXxXguqtMeta || {},
    alias: answer_45availabilityfRfXxXguqtMeta?.alias || [],
    redirect: answer_45availabilityfRfXxXguqtMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/owner/answer-availability.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragen_45klantRzmwaBLHIfMeta?.name ?? "reis-aanvragen-klant",
    path: reis_45aanvragen_45klantRzmwaBLHIfMeta?.path ?? "/reis-aanvragen-klant",
    meta: reis_45aanvragen_45klantRzmwaBLHIfMeta || {},
    alias: reis_45aanvragen_45klantRzmwaBLHIfMeta?.alias || [],
    redirect: reis_45aanvragen_45klantRzmwaBLHIfMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragen8d4c5nANneMeta?.name ?? "reis-aanvragen",
    path: reis_45aanvragen8d4c5nANneMeta?.path ?? "/reis-aanvragen",
    meta: reis_45aanvragen8d4c5nANneMeta || {},
    alias: reis_45aanvragen8d4c5nANneMeta?.alias || [],
    redirect: reis_45aanvragen8d4c5nANneMeta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/reis-aanvragen.vue").then(m => m.default || m)
  },
  {
    name: team_45beheren52VF2fEnw3Meta?.name ?? "team-beheren",
    path: team_45beheren52VF2fEnw3Meta?.path ?? "/team-beheren",
    meta: team_45beheren52VF2fEnw3Meta || {},
    alias: team_45beheren52VF2fEnw3Meta?.alias || [],
    redirect: team_45beheren52VF2fEnw3Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/team-beheren.vue").then(m => m.default || m)
  },
  {
    name: winkelwagenbFY6Wwhgw8Meta?.name ?? "winkelwagen",
    path: winkelwagenbFY6Wwhgw8Meta?.path ?? "/winkelwagen",
    meta: winkelwagenbFY6Wwhgw8Meta || {},
    alias: winkelwagenbFY6Wwhgw8Meta?.alias || [],
    redirect: winkelwagenbFY6Wwhgw8Meta?.redirect,
    component: () => import("C:/Werk/Repos/smookitalia-nuxt-app/pages/winkelwagen.vue").then(m => m.default || m)
  }
]